* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: $font-family;
  font-size: $font-base;
  color: $primary-color;
  background-color: #f8f8f8 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

header {
  padding: 9px 20px;
  background-color: $white;
  .header-logo {
    img {
      margin-right: 15px;
      max-height: 60px;
      height: 54px;
    }
    h1 {
      font-size: $font-lg;
      font-weight: 700;
      line-height: 20px;
      color: $primary;
    }
  }
  .profile {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @media screen and (max-width: 991px) {
      margin-right: 20px;
    }
  }
}

.card {
  @include card;
  border: 0;
  &-title {
    font-size: $font-xl;
    font-weight: 500;
    margin-bottom: 22px;
    @media screen and (max-width: 991px) {
      font-size: $font-lg;
    }
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1550px;
  }
}

.link-text {
  color: $primary;
}
.link-cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.right-content {
  margin: 15px;
  flex-grow: 1;
  margin-left: 300px;
  display: block;
  // width: 1%;
  @media screen and (max-width: 900px) {
    margin-left: calc(70px - -15px);
    &.gis-content {
      margin-left: 15px;
    }
  }
  @media screen and (max-width: 900px) {
    margin-left: calc(70px - -15px);
  }
}

.sub-main {
  background-color: $white;
  padding: 30px 50px;
  min-height: calc(100vh - 170px);
  &.bordered {
    border-top: 5px solid $primary;
  }
  &_title {
    margin-bottom: 30px;
    h2 {
      font-size: $font-xl + 2;
      font-weight: 500;
      color: $primary;
      text-transform: uppercase;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start !important;
      align-items: flex-start !important;
      h2 {
        order: 2;
        margin-top: 30px;
      }
      .download-btn {
        order: 1;
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.header-sticky {
  position: sticky;
  top: 0;
  border-bottom: 1px solid $border-color;
  z-index: 9999;
}

.border-bottom {
  border-bottom: 1px solid $border-color !important;
}

.alert {
  padding: 8px 10px;
}

.loader {
  position: relative;
  width: 100%;
  height: 100%;
  // background-color: #a6a6a66b;
  text-align: center;
  top: 20px;
  i {
    margin-top: 30px;
    font-size: 35px;
  }
}

.ht-100 {
  height: 100px;
}

.data-not-found {
  height: calc(100vh - 105px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 15px;
    width: 250px;
  }
  p {
    font-size: 30px;
    font-weight: 500;
    color: $secondary;
  }
}

.leaflet-div-icon {
  background: none;
  border: 0;
}

.pagination {
  li {
    margin: 0 1px;
  }
}
