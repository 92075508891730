// colors
$primary-color: #292961;
$primary-hover: #4646a6;
$font-color: #474C4F;
$black: #000;
$white: #ffffff;
$success : #198754;
$success-hover: #198754;
$danger: #dc3545;
$warning:#ffc107;
$warning-hover: #ffca2c;

//size
$font-sm: 12px;
$font-base: 14px;
$font-lg: 16px;
$font-xl: 18px;
$font-title: 22px;

//family
$font-family: 'Roboto', sans-serif;
$font-nepali: 'Mukta', sans-serif;

// Buttons
$primary: $primary-color;
// $primary-light: #FAAF4026;
$secondary: #878A99;
$secondary-light: #b4b0b0;
$abstract: #5E84C3;

//radius
$card-radius: 6px;
$img-radius: 4px;

$border-color: #E4E4E4;