@import '../../styles/App.scss';

// .institution-add {
//    min-height: 80vh !important;
// }
.activity {
    background-color: $white;
    padding: 40px 50px;
    border-radius: $card-radius;
    margin-bottom: 6px;
    table {
        th {
            font-weight: 500 !important;
        }
    }
    .category-name {
        font-size: $font-xl + 2;
        font-weight: 500;
        margin-bottom: 30px;
    }
    .dataset-name {
        font-size: $font-xl;
        font-weight: 400;
        margin-bottom: 20px;
    }
    &-wrap {
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-actions {

        &_btn {
            padding: 3px 10px!important;
            height: auto !important;
            margin-right: 10px;
            font-size: $font-sm;
            border-radius: $card-radius;
            border: 0;
            display: inline-block;
            color: $white;
            &.btn-info {
                background-color: #3F9BD5;
            }
            &.btn-danger {
                background-color: #C31521;
            }
            &:hover {
                color: $white;
            }
        }
    }
}

.view-info {
    h5 {
        width: 55%;
        position: relative;
        padding-right: 5px;
    }
    p {
        position: relative;
        width: 45%;
        &::before {
            content: ':';
            position: absolute;
            left: -10px;
        }
    }
}

.btn-alert{
    border-radius: 100px;
    background-color: #008CBA;
    text-decoration: underline;
}

.react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.confirm-alert{
    padding: 20px;
    background-color: #fff;
    border-radius: $card-radius;
    width: 500px;
}
.confirm-alert-title{
    font-size: 20px;
    color: black;
    margin-bottom: 20px;

}
.confirm-alert-button{
    display: inline-block;
}
