@import '../../styles/App.scss';

.banner {
  max-height: 500px;
  position: relative;
  img {
      height: 100%;
      width: 100%;
  }
  .carousel-item {
      max-height: 500px;
      img {
          object-fit: cover;
      }
      @media screen and (max-width: 1440px) {
        max-height: 370px;
      }
      @media screen and (max-width: 1366px) {
        max-height: 260px;
      }
  }
  &-text {
      color: $white;
      position: absolute;
      top: 32%;
      left: 16%;
      h2 {
          font-size: 54px;
          margin-bottom: 24px;
          font-weight: 700;
      }
      p {
          font-size: 28px;
          font-weight: 300;
      }
      @media screen and (max-width:1666px) {
          left: 11%;
      }
      @media screen and (max-width:1536px) {
          left: 8%;
      }
      @media screen and (max-width:1366px) {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          h2 {
              font-size: 45px;
              margin-bottom: 5px;
          }
          p {
              font-size: 25px;
          }
      }
      @media screen and (max-width:767px) {
          h2 {
              font-size: 25px;
          }
          p {
              font-size: 16px;
          }
      }
  }
  @media screen and (max-width:567px) {
      height: 300px;
      img {
          object-fit: cover;
      }
  }
}