@import "../../../styles/App.scss";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "leaflet/dist/leaflet.css";

.form-tabs {
  .nav {
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 58px;
    &-item {
      flex: 1 0 0;
    }
    &-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $font-color;
      position: relative;
      padding: 0;
      span {
        height: 40px;
        width: 40px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        font-weight: 500;
        font-size: $font-xl;
        margin-bottom: 10px;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          background-color: #ffffff;
          border-radius: 2px;
          height: 4px;
          width: 100%;
          top: 20px;
          left: -50%;
          z-index: -1;
          right: 0;
        }
        &.error {
          background-color: $danger;
          color: $white;
        }
      }
      &.active {
        background-color: transparent;
        color: $primary;
        span {
          background-color: $primary;
          color: $white;
          &::before {
            background-color: $primary;
          }
          &.error {
            background-color: $danger;
          }
        }
      }
      &.disabled {
        background-color: transparent;
        color: $secondary-light;
        span {
          background-color: $secondary-light;
          color: $white;
          cursor: not-allowed;
          &::before {
            background-color: $secondary-light;
          }
        }
      }
    }
    .theme-radio {
      margin-top: 10px;
    }
  }

  .sub-main {
    .form-label {
      font-weight: 600;
    }
    .form-control {
      margin-right: 10px;
    }
    .sub-acc.accordion {
      margin-bottom: 10px;
    }
    .border-bottom {
      width: 70%;
    }
  }

  .item-toggle {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 17px 0;
    right: 100px;
    z-index: 10;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 21px;
      flex-shrink: 0;
      margin-left: 5px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .slider {
          background-color: $primary;
        }

        &:focus + .slider {
          box-shadow: 0 0 1px $primary;
        }

        &:checked + .slider:before {
          -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
          transform: translateX(20px);
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &.round {
          border-radius: 65px;

          &:before {
            border-radius: 50%;
          }
        }

        &:before {
          position: absolute;
          content: "";
          height: 15px;
          width: 15px;
          left: 3px;
          bottom: 3px;
          background-color: #fff;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
      }
    }
    p,
    label {
      margin: 0;
    }
  }
}

.map__section_edit#map_edit {
  height: 450px;
  position: relative;
  .leaflet_map {
    background-color: #fafeff !important;
    height: 100%;
    width: 70%;
  }
  @media screen and (max-width: 767px) {
    .leaflet_map {
      width: 100%;
    }
  }
  .layer-checkbox {
    position: absolute;
    top: 10px;
    z-index: 500;
    padding: 5px 10px 2px;
    left: 10px;
    background: #ffffffd9;
    input {
      height: 18px;
      width: 18px;
      border-radius: 0;
    }
    label {
      padding: 2px 0 0 5px;
    }
  }
}

.form-loader {
  position: fixed;
  background: #77747440;
  z-index: 99999;
  top: 75px;
  width: 100%;
  height: calc(100vh - 75px);
}

.searchWrapper {
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 7px 20px;
  min-height: 48px;
  position: relative;
  max-height: 48px;
}
