@import '../../styles/App.scss';

.section-head {
    font-size: $font-title;
    font-weight: 700;
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
        font-size: $font-title;
    }
    @media screen and (max-width: 767px) {
        font-size: $font-xl + 2;
    }
}

.statis {
    padding: 15px !important;
    @include border;
    &-head {
        margin-bottom: 13px;
        h5 {
          font-size: $font-lg;
          color: #474C4F;
          &:first-child {
            width: 285px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          @media screen and (max-width: 991px) {
            font-size: $font-base;
        }
        }
    }
    &-bar {
        .progress {
            margin-bottom: 15px;
            height: 8px;
        }
        &_house_building_standard {
            .progress-bar {
               background-color: #00E198;
            }
        }
        &_house_map_passed{
            .progress-bar {
               background-color: #FEB020;
            }
        }
        &_on_the_basis_of_voters_card{
            .progress-bar {
               background-color: #7468CA;
            }
        }
        &_uneducated{
            .progress-bar {
               background-color: #EE4D65;
            }
        }
        &_unemployed{
            .progress-bar {
               background-color: #018AF3;
            }
        }
        &_disabled{
            .progress-bar {
               background-color: #CD5C5C;
            }
        }
        &_body {
            p {
                font-size: $font-base;
                color: #BBBBBB;
            }
        }
    }
}

.filter {
    .search-btn {
        padding: 5px 18px;
        @media screen and (max-width: 767px) {
            padding: 0 14px;
            height: 40px;
        }
    }
}

.munici-detail {
    .list-group {
        border-radius: $card-radius;
        &-item {
            border-top: 0;
            border-bottom: 1px solid #F7F7F7;
            padding: 12px 20px;
            &:first-child {
                border-top: 1px solid #e4e4e4;
            }
            &:last-child {
                border-bottom: 1px solid #e4e4e4;
            }
            p {
                color: $secondary;
                i {
                    margin-right: 25px;
                }
                &:first-child {
                    width: 300px;
                }
            }
        }
    }
    .theme-table {
        max-height: 92%;
        overflow: auto;
    }
}

.local-block {
    &_item {
        text-align: center;
        border-right: 1px solid #E4E4E4;
        padding: 20px 0;

        h1 {
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 18px;
            @media screen and (max-width: 991px) {
                font-size: 26px;
                margin-bottom: 10px;
            }
        }
        p {
            font-size: $font-lg;
            color: $secondary;
            @media screen and (max-width: 991px) {
                font-size: $font-xl;
            }
        }
    }
    .col {
        &:last-child {
            .local-block_item {
                border: 0 !important;
            }
        }
        @media screen and (max-width: 1199px) {
            width: 33.33%;
            flex: none;
            .local-block_item {
                margin-bottom: 10px;
            }
        }
        @media screen and (max-width: 567px) {
            width: 100%;
            .local-block_item {
                padding: 10px;
                border-right: 0;
                border-bottom: 1px solid $border-color;
            }
        }
    }
}

.pie-chart-section {
    margin: 0 auto;
    &.ownership {
        width: 453px;
    }
    &.building {
        width: 410px;
    }
    // @media screen and (max-width: 1199px) {
    //     width: 50%;
    // }
    // @media screen and (max-width: 991px) {
    //     width: 60%;
    // }
}