@import "../../styles/App.scss";

.gis-links {
    .card {
        border: 1px solid $border-color;
        position: relative;
        &-img-top {
            height: 74px;
            margin-bottom: 15px;
        }
        &-title {
            text-align: center;
            font-size: $font-xl;
            font-weight: 700;
            margin-bottom: 0;
            color: $font-color;
            height: 48px;
            @media screen and (max-width: 1280px) {
              font-size: $font-base !important;
              height: 20px;
            } 
            @media screen and (max-width: 767px) {
                font-size: $font-sm - 1;
                height: auto;
            }
        }
    }
  }
  .row {
    .col {
      @media screen and (max-width: 1199px) {
        width: 33.33%;
        flex: none;
      }
      @media screen and (max-width: 991px) {
        width: 50%;
      }
      @media screen and (max-width: 567px) {
        width: 100%;
        .card {
          margin-bottom: 20px !important;
        }
      }
    }
  }
