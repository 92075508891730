@import "../../styles/App.scss";

.w-50p {
  width: 50%
}

.survey-block {
  @include border;
  border-radius: $card-radius;
  &_title {
    background-color: #f8f8f8;
    padding: 13px 15px;
    color: $font-color;
    text-transform: uppercase;
    font-size: $font-xl;
    font-weight: 600;
    @media screen and (max-width: 767px) {
      font-size: $font-base;
    }
  }
  &_content {
    padding: 20px;
    @media screen and (max-width: 767px) {
      .col {
        width: 100%;
        flex: none;
      }
    }

    @media print {
      .col {
        width: 100%;
        flex: 0 0 auto;
      }
    }

    @media screen and (max-width: 530px) {
      .table-borderless {
        tr {
          display: flex;
          flex-direction: column;
        }
      }
    }
    &-block {
      display: flex;
      color: $font-color;
      margin-bottom: 20px;
      font-family: $font-nepali;
      h5 {
        font-size: 16px;
        font-weight: 500;
        max-width: 200px;
        width: 100%;
        padding-right: 10px;
        position: relative;
        &.mx-w-400 {
          max-width: 400px;
        }
        &::after {
          content: ":";
          position: absolute;
          right: 0;
          top: 0;
        }
        @media screen and (max-width: 480px) {
          max-width: none;
        }
      }

      p {
        padding-left: 10px;
      }
      @media screen and (max-width: 480px) {
        flex-direction: column;
        h5 {
          max-width: none;
          &::after {
            display: none;
          }
        }
        p {
          padding-left: 0;
        }
      }
    }
    .col-lg-6 {
      &:nth-last-child(2) {
        .survey-block_content-block {
          margin-bottom: 0;
        }
      }
      &:last-child {
        .survey-block_content-block {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: 991px) {
        .survey-block_content-block {
          margin-bottom: 20px !important;
        }
      }
    }
  }
  table {
    th {
      font-weight: 500;
    }
  }
  &_table {
    &--title {
      font-size: $font-xl;
      margin-bottom: 20px;
      color: $font-color;
      @media screen and (max-width: 530px) {
        font-size: $font-base;
      }
    }
    table {
      td,
      th {
        color: $font-color;
        font-weight: 500;
        width: 50%;
        @media screen and (max-width: 530px) {
          font-size: $font-sm;
        }
      }
      td {
        border-bottom: 0;
        &:first-child {
          white-space: nowrap;
        }
      }
    }
  }
  span.update-status {
  font-size: 16px;
  font-family: system-ui;
  padding-left: 6px;
    &.added {
      color: $success;
    }
    &.updated {
      color: $primary-hover;
    }
    &.deleted {
      color: $danger;
    }
}

}

.family-member-accordion {
  .accordion-button {
    font-size: $font-lg !important;
  }
}
.download-btn {
  float: right;
}

.alert button.btn-close{
  padding: 10px;
}

@page {
  size: a4;
  margin: 10mm;
}
@media print {
  .pagebreak {
    page-break-before: always;
  }
}
@media print {
  div {
    break-inside: inherit;
  }
  .sub-main_title {
    flex-direction: column;
  }
  .survey-block {
    .col-lg-6 {
      flex: 0 0 auto !important;
      width: 50% !important;
    }
    hr {
      margin-bottom: 0;
    }
    &_content {
      padding-top: 5px;
      &-block {
        margin-bottom: 10px;
        h5 {
          max-width: 180px;
        }
      }
      .accordion-header button::after {
        display: none;
      }
    }
    &_table--title {
      margin-bottom: 10px;
    }
  }
}
