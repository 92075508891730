.dropdown-select {
  .btn {
    background-color: transparent !important;
    color: $secondary !important;
    width: 100%;
    text-align: left;
    text-transform: none !important;
    border: 1px solid $border-color !important;
    padding: 16px 35px 16px 20px;
    font-weight: 400;
    font-size: $font-base !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      color: $secondary !important;
      box-shadow: none;
    }
    &::after {
      position: absolute;
      right: 20px;
    }
    .dropdown-item {
        padding: 10px 15px;
        &.active,
        &:hover {
            background-color: #494994;
            color: $white;
        }
        @media screen and (max-width: 767px) {
            font-size: $font-sm;
        }
    }
  }
  .dropdown-menu {
    width: 100%;
  }
}

.form-lg {
  .form-control,
    textarea {
        padding: 12px 20px;
        margin-bottom: 10px;
        border-radius: $card-radius;
        border: 1px solid $border-color;
        color: $font-color;
        &:focus {
        box-shadow: none;
        }
        &::placeholder {
        color: $secondary;
        }
        input.form-control {
            height: 48px;
        }
    }
  input.form-control {
    height: 48px;
  }
  select.form-control.is-invalid {
    border: 1px solid $danger;
  }
}

.theme-radio {
    .form-check-input {
        position: relative;
        &:checked {
            background-color: #ffffff;
            border-color: #292961;
            background-image: none;
            &::after {
                content: '';
                position: absolute;
                height: 10px;
                width: 10px;
                background-color: $primary;
                border-radius: 100%;
                top: 1px;
                left: 1px;
            }

        }
        &:focus {
            box-shadow: none;
        }
    }
    &_label {
        margin-right: 40px;
    }
}

.agriculture-form {
  td {
    white-space: nowrap;
  }
  input {
    height: 40px;
    width: 180px;
    padding: 10px !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

// input[type="date"]::-webkit-inner-spin-button,
// input[type="date"]::-webkit-calendar-picker-indicator {
//     display: none;
//     -webkit-appearance: none;
// }

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

form {
  input, textarea {
    &.is-invalid {
      border: 1px solid #dc3747 !important;
    }
  }
}