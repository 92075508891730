@import "../../styles/App.scss";

.notice-block {
  .card {
    &-image {
      height: 80px;
      width: 80px;
      border-radius: $img-radius;
      overflow: hidden;
      margin-right: 15px;
      display: flex;
      flex-shrink: 0;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &-block {
      padding: 20px 0;
      border-bottom: 1px solid #f7f7f7;
      p {
        color: $secondary;
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
      &_time {
        white-space: nowrap;
      }
      @media screen and (max-width: 991px) {
        flex-direction: column;
        &_time {
          margin-top: 10px;
          font-size: $font-sm;
        }
      }
    }

    &-block {
      padding: 20px 0;
      border-bottom: 1px solid #f7f7f7;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
      @media screen and (max-width: 1199px) {
        flex-direction: column;
        &_time {
          margin-top: 10px;
          font-size: $font-sm;
        }
      }
    }
    &-block_body {
      max-width: 80%;
      overflow: hidden;
      padding-right: 20px;
      h5 {
        font-size: $font-lg;
        margin-bottom: 10px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        -webkit-box-orient: vertical;
        a {
          color: $font-color;
        }
      }
      p {
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
      @media screen and (max-width: 1199px) {
        max-width: 100%;
        align-items: center;
      }
    }
  }
}

.view-all {
  font-size: $font-xl;
  font-weight: 500;
  color: $font-color;
  margin: 0 0 30px 10px;
}

.top-section {
  display: flex;
  justify-content: space-between;
  .breadcrumb {
    margin: 30px 0 28px;
    &-item {
      font-size: $font-lg;
      font-weight: 500;
      a {
        color: $font-color;
        &:hover {
          color: $abstract;
        }
      }
    }
    .breadcrumb-item + .breadcrumb-item::before {
      content: var(--bs-breadcrumb-divider, ">") !important ;
    }
  }
  .myNotice{
    margin-top: 30px;
  }
}

.notice-list {
  padding: 0 !important;
}

.notice-detail {
  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    h5 {
      font-size: $font-lg;
      font-weight: 500;
      margin-bottom: 0;
    }
    .date {
      font-size: $font-base;
      color: $secondary;
      font-weight: 400;
      i {
        margin-right: 10px;
      }
    }
  }
  &_img {
    margin-bottom: 30px;
    border-radius: $img-radius;
    overflow: hidden;
    height: 341px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &_description {
    font-size: $font-base;
    font-weight: 300;
    color: $secondary;
    line-height: 25.6px;
  }
}
